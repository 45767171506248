import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91model_93NTGQgqA62vMeta } from "/opt/build/repo/pages/[cc]/elektroauto/[make]/[model].vue?macro=true";
import { default as _91model_939vMNxTzrrtMeta } from "/opt/build/repo/pages/[cc]/voiture-electrique/[make]/[model].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as component_45stubVERwtJA66OMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVERwtJA66O } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cc-elektroauto-make-model",
    path: "/:cc()/elektroauto/:make()/:model()",
    component: () => import("/opt/build/repo/pages/[cc]/elektroauto/[make]/[model].vue").then(m => m.default || m)
  },
  {
    name: "cc-voiture-electrique-make-model",
    path: "/:cc()/voiture-electrique/:make()/:model()",
    component: () => import("/opt/build/repo/pages/[cc]/voiture-electrique/[make]/[model].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/upcycling/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/schadenmeldung/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/schadenmeldung/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/citroen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/fiat/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/hyundai/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/kia/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/mg/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/peugeot/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/renault/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/volkswagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/der-grosse-vergleich-der-verschiedenen-ladetarife/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/der-grosse-vergleich-der-verschiedenen-ladetarife/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/tesla-cybertruck-eu-version-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques-delais-de-livraison-actualises/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/fisker-ocean-leasing-zum-bestpreis/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/vw-id-buzz-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/tesla-cybertruck-eu-version-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-buzz/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/byd-leasing-deutschland/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/gebrauchtwagen-schnaeppchen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto-leasing-von-gebraucht-und-jungwagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/audi/e-tron-sportback-50-quattro/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/opel/mokka-e/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/bmw/ix3/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-4-1st-edition/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/audi/q4-40-e-tron/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/seat/mii-electric/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/skoda/enyaq-iv-sportline-80x/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/skoda/enyaq-iv-80/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-performance-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/dacia/spring/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/bmw/i4-edrive40/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/skoda/enyaq-iv-sportline-80/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/ds/3-crossback-e-tense/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/honda/e/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/bmw/ix3/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/kia-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/kia-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/fiat-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/fiat-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/skoda-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/skoda-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/tesla-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/tesla-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/pp/l/tesla-leasing-france/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/volkswagen-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/volkswagen-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/bmw-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/bmw-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/peugeot-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/peugeot-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/pp/l/leasing-peugeot/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/renault-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/renault-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/pp/l/renault-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/hyundai-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/hyundai-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/cupra-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/cupra-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/nissan-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/audi-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/audi-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/mercedes-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/byd-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/jeep-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/mg-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/mg-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/pp/l/mg-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/polestar-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/polestar-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/pp/l/ford-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/pp/l/ford-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/pp/l/citroen-leasing/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/citroen-e-c3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/citroen-e-c3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/vw-id-2-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/vw-id-2-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/renault-5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/renault-5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/skoda-elroq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/skoda-elroq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/hyundai-casper-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/hyundai-casper-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/electric-brands-xbus-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/electric-brands-xbus-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/ford-puma-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/ford-puma-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/fiat-600e-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/fiat-600e-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/mini-cooper-electric-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/mini-cooper-electric-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/byd-seagull-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/byd-seagull-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/vinfast-vf6-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/vinfast-vf6-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/omoda-5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/omoda-5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/leapmotor-t03-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/leapmotor-t03-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/mg-cyberster-roadster-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/mg-cyberster-roadster-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/ford-explorer-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/ford-explorer-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/kia-ev9-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/kia-ev9-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/smart-formore-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/smart-formore-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/byd-seal-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/byd-seal-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/cupra-tavascan-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/cupra-tavascan-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/peugeot-e-308-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/peugeot-e-308-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/lotus-eletre/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/lotus-eletre/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/porsche-boxster-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/porsche-boxster-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/porsche-boxster-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/smart-3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/smart-3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/zeekr-x-003-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/zeekr-x-003-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/polestar-4-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/polestar-4-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/rolls-royce-spectre-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/tesla-model-q-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/tesla-model-q-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/vw-id-space-vizzion/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/vw-id-space-vizzion/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/genesis-gv60-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/genesis-gv60-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/fiat-centoventi-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/fiat-centoventi-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/nio-et5-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/nio-et5-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/opel-astra-e-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/opel-astra-e-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/skoda-enyaq-iv-coupe/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/skoda-enyaq-iv-coupe/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/toyota-bz4-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/toyota-bz4-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/nio-et7-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/nio-et7-bestellen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/nissan-ariya/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/nissan-ariya/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/renault-clio-elektro-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/renault-clio-elektro-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/audi-a2-e-tron-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/audi-a2-e-tron-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/kia-ev5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/kia-ev5-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/peugeot-e-5008-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/peugeot-e-5008-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/renault-scenic-e-tech-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/renault-scenic-e-tech-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/renault-twingo-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/renault-twingo-ev-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/skoda-epiq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/skoda-epiq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/rivian-r3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/rivian-r3-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/dacia-sandero-elektro-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/dacia-sandero-elektro-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/rivian-r2-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/rivian-r2-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/toyota-bz-small-crossover-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/toyota-bz-small-crossover-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/lancia-ypsilon-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/lancia-ypsilon-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/toyota-bz2x-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/toyota-bz2x-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/cadillac-lyriq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/cadillac-lyriq-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/byd-seal-u-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/byd-seal-u-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/aktionen",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/aktionen",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/instadrive_agb_de",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/instadrive_agb_at",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/instadrive_agb_ch",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/CGV_INSTADRIVE_FR_2024-01-16",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/Leistungsbeschreibung_INSTADRIVE_DE_2023-06-30",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/Leistungsbeschreibung_INSTADRIVE_AT_2023-06-30",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/Leistungsbeschreibung_INSTADRIVE_CH_2023-06-30",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/Description_Prestations_INSTADRIVE_FR_2024-01-16",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/blog/aktueller-ausbau-der-ladeinfrastruktur/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/blog/aktueller-ausbau-der-ladeinfrastruktur/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/marktfuhrer-garantiert-600-euro-epramie/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto-leasing-von-gebraucht-und-jungwagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/e-auto-per-gehaltsumwandlung/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/vw-id-buzz-kommt-als-langversion/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/gratis-parken-fuer-elektroautos-in-oesterreich/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/equote-in-oesterreich-geld-verdienen-mit-dem-e-auto/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/blog/thg-quote/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/blog/steuervorteile-elektroauto/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/fisker-ocean-kaufen-leasen-abonnieren/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/fisker-ocean-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/togg-t10f-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/togg-t10f-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/rolls-royce-spectre-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/togg-e-suv-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/togg-e-suv-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/instadrive-e2c-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/instadrive-e2c-kaufen-abonnieren-leasen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/aktuelle-lieferzeiten-elektroautos-oesterreich/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/aktuelle-lieferzeiten-elektroautos-deutschland/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/audi/q8-50-e-tron-quattro/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/bmw/i3-120ah/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/bmw/i5-edrive40/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/fiat/500e-cabrio-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/ford/mustang-mach-e-er-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/kona-elektro-2-39kwh/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/kona-elektro-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/kona-elektro-my2022/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/ioniq-6-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/ioniq-6-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/ioniq-5-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/hyundai/ioniq-5-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/kia/e-niro-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/kia/e-niro-gebraucht-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/kia/ev6-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/kia/ev6-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/mercedes/eqe-300-suv/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/mg/mg-zs-ev/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/nissan/ariya-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/nissan/leaf-e/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/nissan/nissan-leaf/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/peugeot/e-expert/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/renault/zoe-e-tech/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/renault/megane-e-tech/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/skoda/enyaq-iv-80-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/smart/smart-eq-fortwo/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-sr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-lr-my2018/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-lr-my2021/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-sr-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-3-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-y-hinterradantrieb/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/tesla/model-y-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-3-pure-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-3-pro-performance-3/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-4-pure/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-4-pro-performance-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-4-gtx/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/elektroauto/volkswagen/id-4-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/audi/q4-40-e-tron/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/ford/mustang-mach-e-er/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/hyundai/kona-elektro-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/hyundai/ioniq-5-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/hyundai/ioniq-5-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/kia/ev6-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/kia/e-niro-64kwh/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/kia/e-niro-39kwh",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/kia/e-niro-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/mini/cooper-se/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/nissan/leaf-e/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/peugeot/e-2008-my2019/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/skoda/enyaq-iv-80/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/smart/smart-eq-fortwo/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-lr-my2021/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-performance-3/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-lr-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-sr-my2021/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-3-sr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-y-hinterradantrieb-gebrauchtwagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-y-lr-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-y-hinterradantrieb/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/tesla/model-y-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-4-pro-performance-gebrauchtwagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-4-gtx/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-buzz-pro/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-5-gtx/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-3-pro-performance-jungwagen/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-3-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-3-pro-performance-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/elektroauto/volkswagen/id-3-pro-s/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/audi/q4-45-e-tron/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/audi/q4-50-e-tron/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/cupra/born-58-e-boost/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/ford/mustang-mach-e-er/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/ford/mustang-mach-e-sr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/hyundai/ioniq-5-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/hyundai/ioniq-5-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/kia/ev6-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/kia/ev6-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/opel/corsa-e-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/opel/opel-mokka-e/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/renault/megane-e-tech/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/skoda/skoda-enyaq-iv-sportline-80x/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/skoda/skoda-enyaq-iv-sportline-80/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/skoda/enyaq-iv-80/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/skoda/enyaq-iv-80x/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-3-lr-gebraucht/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-3-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/tesla-model-3-sr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-3-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-y-performance-2/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-y-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/tesla/model-y-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/volkswagen/id-3-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/volkswagen/id-4-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/volkswagen/id-4-gtx/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/ch/elektroauto/vw/id4/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/citroen/e-c3/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/fiat/fiat-500e-coupe/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/fiat/fiat-500e-cabrio/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/hyundai/hyundai-kona-electric/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/hyundai/hyundai-ioniq-5-lr-awd/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/hyundai/hyundai-ioniq-5-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/kia/kia-niro-ev/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/mg/mg-4/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/peugeot/peugeot-e-2008/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/peugeot/peugeot-e-208/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/renault/renault-twingo-e-tech-electrique/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/renault/renault-megane-e-tech-electric-ev60/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/model-y-propulsion/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/tesla-model-y-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/tesla-model-3-lr/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/tesla-model-3-propulsion/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/tesla/tesla-model-3-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/volkswagen/vw-id-4-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/volkswagen/vw-id-4-gtx/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/fr/voitures-electriques/volkswagen/id-3-pro-performance/",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/tesla-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/volkswagen-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/mg-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/mg-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/hyundai-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/polestar-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/tesla-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/audi-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/hyundai-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/bmw-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/kia-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/renault-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/polestar-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/kia-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/at/mercedes-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/cupra-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/peugeot-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/skoda-leasing",
    component: component_45stubVERwtJA66O
  },
  {
    name: component_45stubVERwtJA66OMeta?.name,
    path: "/de/hyundai-leasing",
    component: component_45stubVERwtJA66O
  }
]